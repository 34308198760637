import VideoPlayer from 'components/Misc/VideoPlayer';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import { useRef } from 'react';

interface HeaderSubPageProps {
  imageUrl?: string;
  imageAlt?: string;
  title: string;
  videoUrl?: string;
}

export default function HeaderSubPage({
  imageUrl,
  imageAlt = '',
  title,
  videoUrl,
}: HeaderSubPageProps) {
  const anchorRef = useRef(null);
  const handleClick = (event) => {
    event.preventDefault();
    anchorRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative">
      <div className="mx-auto w-full">
        <div className="relative overflow-hidden">
          <div className="absolute flex h-screen w-full justify-center">
            {imageUrl ? (
              <Image
                src={imageUrl}
                alt={imageAlt}
                fill
                sizes="(max-width: 1024px) 100vh, 100vw" // use vh on mobile to avoid image getting blurry
                quality={90}
                className="h-screen object-cover"
              />
            ) : (
              <>
                {videoUrl && (
                  <VideoPlayer
                    preload="auto"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={videoUrl}
                    className="absolute h-screen w-auto min-w-full max-w-none object-cover"
                  />
                )}
              </>
            )}
          </div>
          <div className="relative flex h-screen justify-center px-6 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-32">
            <h2 className="flex items-center text-center text-subpage-title-m font-light uppercase tracking-tight text-heading lg:text-subpage-title-d">
              {title}
            </h2>
          </div>
          <div className="absolute bottom-10 flex w-full items-center justify-center">
            <a onClick={handleClick} href="#">
              <svg
                className="h-10 w-10 text-tt-white opacity-60"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div ref={anchorRef} className="absolute bottom-0"></div>
    </div>
  );
}
