import { absoluteUrl } from 'lib/utils';
import HeaderSubPage from 'components/HeaderSubPage';

export default function P1HeaderSubPage({ paragraph }) {
  let imageUrl = '';
  let imageAlt = '';
  if (paragraph?.field_image?.field_media_image) {
    imageUrl = absoluteUrl(paragraph.field_image.field_media_image.uri?.url);
    imageAlt =
      paragraph?.field_image?.field_media_image?.resourceIdObjMeta?.alt;
  }
  const title = paragraph?.field_title;
  const videoUrl = paragraph?.field_video_url;

  return (
    <HeaderSubPage
      imageUrl={imageUrl}
      imageAlt={imageAlt}
      title={title}
      videoUrl={videoUrl}
    />
  );
}
