import ArrowLink from 'components/Misc/ArrowLink';
import VideoPlayer from 'components/Misc/VideoPlayer';
import Image from 'next/image';

const layoutData = [
  'col-start-1 col-end-13 row-start-1 row-end-2 h-[11.5rem] lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-3 lg:h-80',
  'col-start-1 col-end-7 row-start-2 row-end-3 h-[11.5rem] lg:col-start-3 lg:col-end-5 lg:row-start-1 lg:row-end-3 lg:h-80',
  'col-start-7 col-end-13 row-start-2 row-end-3 h-[11.5rem] lg:col-start-5 lg:col-end-6 lg:row-start-1 lg:row-end-5 lg:h-160',
  'col-start-1 col-end-13 row-start-3 row-end-4 h-[11.5rem] lg:col-start-1 lg:col-end-5 lg:row-start-3 lg:row-end-5 lg:h-80',
  'col-start-1 col-end-7 row-start-4 row-end-5 h-[11.5rem]  lg:col-start-1 lg:col-end-4 lg:row-start-5 lg:row-end-9 lg:h-160',
  'col-start-7 col-end-13 row-start-4 row-end-5 h-[11.5rem] lg:col-start-4 lg:col-end-6 lg:row-start-5 lg:row-end-7 lg:h-80',
  'col-start-1 col-end-13 row-start-5 row-end-6 h-[11.5rem] lg:col-start-4 lg:col-end-6 lg:row-start-7 lg:row-end-9 lg:h-80',
  'col-start-1 col-end-7 row-start-6 row-end-7 h-[11.5rem] lg:col-start-1 lg:col-end-3 lg:row-start-9 lg:row-end-11 lg:h-80',
  'col-start-7 col-end-13 row-start-6 row-end-7 h-[11.5rem] lg:col-start-3 lg:col-end-6 lg:row-start-9 lg:row-end-11 lg:h-80',
];

const imageStyle = [
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_150x660',
  'focal_point_gallery_image_660x320',
  'focal_point_gallery_image_490x650',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_660x320',
];

export default function ImageWallItem({ imageWallItem, index }) {
  return (
    <figure className={`relative ${layoutData[index]}`}>
      {imageWallItem?.field_video_url ? (
        <VideoPlayer
          autoPlay
          loop
          muted
          playsInline
          src={imageWallItem?.field_video_url}
          className="absolute h-full w-full object-cover"
        />
      ) : (
        <Image
          fill
          quality={100}
          sizes="(max-width: 1024px) 100vw, 800px"
          src={
            imageWallItem?.field_image?.field_media_image?.image_style_uri?.[
              imageStyle[index] ||
                imageWallItem?.field_image?.field_media_image?.uri?.url
            ]
          }
          alt={
            imageWallItem.field_image?.field_media_image?.resourceIdObjMeta?.alt
          }
          className="object-cover brightness-90"
        />
      )}
      {imageWallItem?.field_link?.full_url && (
        <div className="overflow-wrap-anywhere absolute bottom-2 left-4 hyphens-auto lg:break-normal">
          <ArrowLink inverted href={imageWallItem?.field_link?.full_url ?? ''}>
            <span className="text-lg lg:text-xl">
              {imageWallItem?.field_link?.title}
            </span>
          </ArrowLink>
        </div>
      )}
    </figure>
  );
}
