import { useEffect, useRef } from 'react';
import Hls from 'hls.js';

interface HLSVideoProps extends React.HTMLProps<HTMLVideoElement> {
  src: string;
}

const HLSVideo = ({ src, ...props }: HLSVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let hls: Hls | null = null;
    if (!videoRef.current) return;

    if (Hls.isSupported()) {
      hls = new Hls({
        capLevelToPlayerSize: true,
        debug: false,
      });
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = src;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  return <video ref={videoRef} {...props}></video>;
};

export default HLSVideo;
