import VideoPlayer from 'components/Misc/VideoPlayer';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';

export default function P7FullScreenMedia({ paragraph }) {
  return (
    <div data-paragraph-type="P7FullScreenMedia">
      <div className="relative flex h-[20rem] w-full items-center justify-center overflow-hidden lg:h-[42rem]">
        {paragraph?.field_image ? (
          <Image
            src={absoluteUrl(paragraph.field_image.field_media_image?.uri?.url)}
            alt={paragraph.field_image.field_media_image.resourceIdObjMeta?.alt}
            fill
            sizes="100vw"
            quality={90}
            className="object-cover"
          />
        ) : (
          <>
            {paragraph?.field_video_url && (
              <VideoPlayer
                autoPlay
                loop
                muted
                playsInline
                className="absolute h-full w-auto min-w-full max-w-none object-cover"
                src={paragraph.field_video_url}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
