import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';

const P21Disruptor = ({ paragraph }) => {
  const block = paragraph?.field_block;
  if (!block) {
    return null;
  }
  const imageUrl = block?.field_image?.field_media_image?.uri?.url;
  const altText = block?.field_image?.field_media_image?.field_image_alt_text;
  const title = block?.field_image?.field_media_image?.field_image_title_text;
  const link = block?.field_link?.uri || '#';

  return (
    <div className="absolute -left-6 top-[80vh] z-10 h-36 w-36 lg:-left-8 lg:top-[70vh] lg:h-50 lg:w-50">
      <Link href={link}>
        <Image
          src={absoluteUrl(imageUrl)}
          alt={altText}
          fill
          title={title}
          className="rounded-full"
        />
      </Link>
    </div>
  );
};

export default P21Disruptor;
