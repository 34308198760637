import HLSVideo from './HLSVideo';

interface VideoPlayerProps extends React.HTMLProps<HTMLVideoElement> {
  src: string;
}

export default function VideoPlayer({ src, ...props }: VideoPlayerProps) {
  const isHLSFile = src.includes('.m3u8');

  if (isHLSFile) {
    return <HLSVideo {...props} src={src} />;
  }
  return (
    <video {...props}>
      <source src={src} type="video/mp4" />
    </video>
  );
}
